import Head from 'next/head'
import React, { memo } from 'react'
import styled, { css } from 'styled-components'

export const StyledVideo = styled.video<{ aspectRatio?: string }>`
  outline: none;
  border: none;

  ${({ aspectRatio }) =>
    aspectRatio &&
    css`
      aspect-ratio: ${aspectRatio};
    `};
`

export type TOptimizedVideoProps = {
  mp4: string
  poster: string
  webm?: string
  preload?: boolean
  aspectRatio?: string
}

const OptimizedVideo = ({
  webm,
  mp4,
  poster,
  preload,
  aspectRatio,
}: TOptimizedVideoProps) => {
  return (
    <>
      {preload && (
        <Head>
          {/* @ts-expect-error fetchpriority is not yet defined in HTMLLinkElement */}
          <link rel='preload' as='image' href={poster} fetchpriority='high' />
        </Head>
      )}
      <StyledVideo
        aspectRatio={aspectRatio}
        poster={poster}
        width='100%'
        // @ts-expect-error lazy is not yet defined in HTMLVideoElement
        lazy
        loop
        muted
        autoPlay
        playsInline
        preload={poster ? 'none' : 'metadata'}
        disableRemotePlayback
        x-webkit-airplay='deny'
        key={mp4 || webm}
      >
        {webm && (
          <source data-testid='currentWebmVideo' src={webm} type='video/webm' />
        )}
        <source data-testid='currentDefaultVideo' src={mp4} type='video/mp4' />
      </StyledVideo>
    </>
  )
}

OptimizedVideo.defaultProps = {
  preload: false,
}

export default memo(OptimizedVideo)
